.introHeader {
    padding: 25vh 0;
    text-align: center;
    margin: auto;
    width: auto;
    height: auto;
    overflow: hidden;
    background-color:  #ffa64d;
    border: 1px solid #ff9933;
    font-family: 'Crete Round';
    color: white;
}

.myName {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    font-size: 120px;
}

.myNickName {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    font-size: 80px;

}

.mySideNote {
    text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    font-size: 30px;

}

.aboutSection {
    text-align: left;
    margin: auto;
    height: auto;
    overflow: hidden;
    background-color:  #3399ff;
    font-family: 'Crete Round';
    color: white;
    border: 1px solid #1a8cff;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
}

.aboutContainer {
    margin: 100px;
}

.myImage {
    justify-content: center;
    align-items: center;
    border-radius: 1000px;
    box-shadow: 10px 10px 5px rgba(0,0,0,0.3);
    -moz-box-shadow: 10px 10px 5px rgba(0,0,0,0.3);
    -webkit-box-shadow: 10px 10px 5px rgba(0,0,0,0.3);
    -khtml-box-shadow: 10px 10px 5px rgba(0,0,0,0.3);
}

.whoAmI {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    font-size: 60px;
    text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
}

.myStory {
    font-size: 20px;
    text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
}