.myContact {
    text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    font-family: 'Crete Round';
    margin: auto;
    width: auto;
    height: 100vh;
    padding: 25vh 0vh 25vh 0vh;
    text-align: center;
    height: auto;

    
}

.contactTitle {
    font-size: 80px;
}

.emailLink {
    font-size: 20px;
}

.socialMedia {
    width: 100%;
    margin: auto;
    padding: auto;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
}