@import url(https://fonts.googleapis.com/css2?family=Crete+Round&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.homePage_introHeader__1RMOL {
    padding: 25vh 0;
    text-align: center;
    margin: auto;
    width: auto;
    height: auto;
    overflow: hidden;
    background-color:  #ffa64d;
    border: 1px solid #ff9933;
    font-family: 'Crete Round';
    color: white;
}

.homePage_myName__1W2WM {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    font-size: 120px;
}

.homePage_myNickName__F8rnG {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    font-size: 80px;

}

.homePage_mySideNote__1rpnE {
    text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    font-size: 30px;

}

.homePage_aboutSection__1qCT6 {
    text-align: left;
    margin: auto;
    height: auto;
    overflow: hidden;
    background-color:  #3399ff;
    font-family: 'Crete Round';
    color: white;
    border: 1px solid #1a8cff;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
}

.homePage_aboutContainer__1R86X {
    margin: 100px;
}

.homePage_myImage__3M8P6 {
    justify-content: center;
    align-items: center;
    border-radius: 1000px;
    box-shadow: 10px 10px 5px rgba(0,0,0,0.3);
    -moz-box-shadow: 10px 10px 5px rgba(0,0,0,0.3);
    -webkit-box-shadow: 10px 10px 5px rgba(0,0,0,0.3);
    -khtml-box-shadow: 10px 10px 5px rgba(0,0,0,0.3);
}

.homePage_whoAmI__Vs6W5 {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    font-size: 60px;
    text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
}

.homePage_myStory__B8elR {
    font-size: 20px;
    text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
}
.contactPage_myContact__2xvEm {
    text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    font-family: 'Crete Round';
    margin: auto;
    width: auto;
    height: 100vh;
    padding: 25vh 0vh 25vh 0vh;
    text-align: center;
    height: auto;

    
}

.contactPage_contactTitle__3c9AY {
    font-size: 80px;
}

.contactPage_emailLink__2UsMN {
    font-size: 20px;
}

.contactPage_socialMedia__3CxU1 {
    width: 100%;
    margin: auto;
    padding: auto;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
}
